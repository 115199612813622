// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Leaderboard',
    icon: <Iconify icon={'ph:chart-donut'} {...ICON_SIZE} />,
    path: '/leaderboard',
  },
  {
    title: 'Docs',
    icon: <Iconify icon={'f7:doc-plaintext'} {...ICON_SIZE} />,
    path: 'https://docs.purrpose.xyz/',
  },
];

export default menuConfig;
